import React from "react"
import AppNavigatorLinkBank from "@components/app_navigator/AppNavigatorLinkBank"
const isClient = typeof window !== "undefined"

export default function IndividualLinkBank() {
  let redirect_url = isClient ? window.location.href : "/"
  let search = isClient ? window.location.search : ""

  return <AppNavigatorLinkBank redirect_url={redirect_url} search={search} />
}
